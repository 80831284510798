import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Education" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-40">
        <div class="format_text">
          <h1>Education</h1>
          <table style={{ marginTop: 0 }}>
            <tbody>
              <tr>
                <td>
                  <p>
                    <StaticImage src="../images/shutterstock_53884903-Education-300x200.jpeg" alt="Columns" />
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h2>
                    Oklahoma City University School of Law, J.D., Oklahoma City,
                    OK
                  </h2>
                  <ul>
                    <li>
                      Certificates: Business Law – Financial Services and
                      Commercial Law Concentration; Public Law
                    </li>
                    <li>President, Energy Law Association</li>
                    <li>CALI Award, Legal Research &amp; Writing II</li>
                    <li>
                      <em>Delta Theta Phi Law Fraternity</em>
                      <em> </em>
                    </li>
                    <li>
                      (Attended law school while maintaining full-time job)
                    </li>
                  </ul>
                  <h2>University of Buenos Aires, Argentina (Summer 2008)</h2>
                  <ul>
                    <li>
                      Studied international business, constitutional and human
                      rights law
                    </li>
                  </ul>
                  <h2>
                    Nankai University, Tianjin, People’s Republic of China
                    (Summer 2007)
                  </h2>
                  <ul>
                    <li>
                      Studied Chinese legal system, securities markets, and
                      int’l taxation
                    </li>
                  </ul>
                  <h2>University of Texas at Dallas; Richardson, TX</h2>
                  <ul>
                    <li>
                      Post-graduate studies in computer science &amp; management
                      information systems
                    </li>
                  </ul>
                  <h2>
                    School of Mortgage Banking, Northwestern University;
                    Evanston, IL
                  </h2>
                  <ul>
                    <li>Three-year certificate program</li>
                  </ul>
                  <h2>The George Washington University; Washington, DC</h2>
                  <ul>
                    <li>MBA (finance and investments)</li>
                  </ul>
                  <h2>The American University; Washington, DC</h2>
                  <ul>
                    <li>BA (government and international relations)</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
